<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.park_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="''"
                  :operationId="''"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Date_search')" prop="dateType">
                <el-select
                  v-model.trim="formInline.dateType"
                  placeholder="请选择"
                  @change="timeTypeChange"
                  class="time_type"
                >
                  <el-option label="日" value="1"></el-option>
                  <el-option label="月" value="2"></el-option>
                </el-select>
                <el-date-picker
                  v-model="searchTime"
                  @change="searchTimeChange"
                  :clearable="false"
                  :type="timeType"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.tabs.personSta.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="exportFile"
                :loading="loading"
                v-if="$route.meta.authority.tabs.personSta.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myComponent from "@/components/autocomplete/myautoComponent";
// import uploadFile from '@/components/uploadFile/';
import { dateFormat, exportExcelNew } from "@/common/js/public";
export default {
  components: {
    // uploadFile,
    myComponent,
  },
  data() {
    let today = new Date();
    let yesterday = new Date(new Date().getTime() - 86400000 * 31);
    return {
      dataAll: 1,
      formInline: {
        dateType: "1",
        parkId: "",
        startDate: yesterday,
        endDate: today,
      },
      timeType: "daterange",
      searchTime: [yesterday, today],
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "complaintNum",
          label: "已处理投诉量",
          width: "",
        },
        {
          prop: "percentage",
          label: "投诉占比",
          width: "",
          formatter: (row) => {
            return (row.percentage * 100).toFixed(2) + "%";
          },
        },
      ],
      loading: false,
      page: 0,
      pageSize: 10,
      total: 0,
      uploadData: {},
      uploadUrl: "/acb/2.0/bar/import",
      modelUrl: "/acb/2.0/bar/downloadExcelTemplate",
    };
  },
  methods: {
    exportFile() {
      if (!this.formInline.startDate || !this.formInline.endDate) {
        this.$alert("请选择时间");
        return;
      }
      exportExcelNew("/acb/2.0/bigDataCount/complaintparkExport", { ...this.formInline });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    timeTypeChange() {
      if (this.formInline.dateType === "2") {
        this.timeType = "monthrange";
      } else {
        this.timeType = "daterange";
      }
      this.searchTimeChange();
    },
    searchTimeChange() {
      let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
      this.formInline.startDate = dateFormat(this.searchTime[0], tmp);
      this.formInline.endDate = dateFormat(this.searchTime[1], tmp);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    searchData() {
      if (!this.formInline.startDate || !this.formInline.endDate) {
        this.$alert("请选择时间");
        return;
      }
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bigDataCount/complaintpark", {
          data: {
            ...this.formInline,
            pageNo: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          this.tableData = [];
          this.tableData = res.value.data;
          this.dataAll = res.value.dataAll;
          this.total = res.value.total * 1 || 0;
        });
    },
  },
  mounted() {
    this.searchTimeChange();
    this.searchData();
  },
};
</script>

<style lang="stylus" scoped>
::v-deep .time_type {
  margin-right:10px
  .el-input__inner{
    width:72px
  }
}
::v-deep .date_picker{
  width:136px
  .el-input__inner{
    width:140px
  }
}
</style>
