<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <el-tabs style="padding-left: 10px" class="bgFFF" v-model="activeName" @tab-click="handleTab">
        <el-tab-pane label="车场维度" name="1">
          <complainSta ref="personSta" v-if="authority.tabs['complainSta_t']"></complainSta>
        </el-tab-pane>
        <el-tab-pane label="人员维度" name="2">
          <personSta ref="personSta" v-if="authority.tabs['personSta']"></personSta>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import complainSta from "../complainSta/complainSta.vue";
import personSta from "./personSta.vue";

export default {
  name: "payRepeat",
  components: {
    complainSta,
    personSta,
  },
  data() {
    return {
      authority: this.$route.meta.authority,
      activeName: "1",
    };
  },
  methods: {
    handleTab(vm) {
      //   vm.name == 1 ? this.$refs.payRepeatList.searchData() : this.$refs.payRepeatRefund.searchData()
    },
  },
  created() {
    console.log("工作统计权限", this.$route.meta.authority);
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
